import { Button } from "reactstrap"

const MyPartyCard = (props) => {
    return (
        <div className="card bg-dark border-0" style={{ zIndex: 1 }}>
            <div className="card-body p-3">
                <div className="row align-items-center">
                    <div className="col">
                        <h4 className="text-white font-weight-400 mb-0">{ props.name }</h4>
                        <small className="font-weight-300 text-s mb-0">{ props.url }</small>
                    </div>
                    <div className="col-auto text-right">
                        <Button className="font-weight-400 text-s border-dark" size="sm" type="button" style={{backgroundColor: '#1c1c21', fontSize: '10.5px'}} onClick={() => props.rejoinParty(props.id, props.url)}>
                            Rejoin
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyPartyCard