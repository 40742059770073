import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
	CardImg,
    CardBody
} from "reactstrap";

import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header";
  
  const BlogReading = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Read our blog</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../blog'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container">
                    <div className="row py-4">
                        <div className="col-sm-12 col-md-4 mb-4">
                            <Card className="bg-dark">
                                <CardImg
                                    alt="Watch Together - bingeAsk"
                                    src={require("../assets/img/ott-icons/netflix-bg.webp")}
                                    className="img-fluid h-100"
                                    width="600" height="400"
                                    rel="preload"
									type="image/webp"
                                />
                                <CardBody className="bg-dark">
                                    <h5 className="text-light-gray mb-0">Author: Kawalpreet Singh Juneja</h5>
                                    <small className="text-gray">Posted on: 23-11-2023</small>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <h1 className="font-weight-700 text-light-gray" style={{fontSize: '20px'}}>How to watch Netflix together?</h1>
                            <small className="text-light-gray mb-0 mt-4">
                                <b>Chapter 1: The Basics of Watching Netflix Together</b><br />
                                Learn about the various tools and platforms that allow you to sync up your Netflix viewing. From browser extensions to dedicated apps, discover the easiest ways to bridge the gap and enjoy movies and series simultaneously.
                            </small>
                        </div>
                    </div>
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default BlogReading;
  