import { useState } from "react"
import { Input } from "reactstrap"
import showAlert from '../Alert'
import axios from "axios"

const SignUpCard = (props) => {
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const signin = () => {
        if ((phone.length !== 10) || isNaN(phone)) {
            showAlert('danger', <b>Please enter a correct phone number</b>)
            return
        }
        if(password === '') {
            showAlert('danger', <b>Password can't be empty</b>)
            return
        }
        setLoading(true)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}auth/login`, {
            phone,
            password
        }).then((resp) => {
            resp = resp.data
            localStorage.setItem('jwt', resp.token)
            localStorage.setItem('userEmail', resp.user.user_email)
            localStorage.setItem('pfp', resp.user.user_pfp)
            localStorage.setItem('userPhone', resp.user.user_phone)
            localStorage.setItem('userName', resp.user.user_name)
            localStorage.setItem('userId', resp.user.user_id)
            props.loadProfile()
        }).catch((err) => {
            showAlert('danger', <b>{err.response.data.message}</b>)
        }).then(() => {
            setLoading(false)
        })
    }

	return (
		<>
			<div className="row justify-content-center mt-4 mx-0" style={{height: '70vh'}}>
                <div className="col-lg-4 col-md-7">
                    <div className="card bg-dark border-0 mb-0">
                        <div className="card-body px-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h2 className="text-light-gray h4">Sign in</h2>
                            </div>
                            <form>
                                <div className="form-group mb-3">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-light-dark border-0"><i className="ni ni-email-83"></i></span>
                                        </div>
                                        <Input className="form-control bg-light-dark border-0" placeholder="Enter phone number" value={phone} onInput={(e) => setPhone(e.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group input-group-merge input-group-alternative">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-light-dark border-0"><i className="ni ni-lock-circle-open"></i></span>
                                        </div>
                                        <input className="form-control bg-light-dark border-0" placeholder="Password" type="password" value={password} onInput={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="text-center">
                                    { loading ? (
                                        <button type="button" className="btn btn-primary my-4" onClick={() => signin()}>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Signing you in...
                                        </button>
                                    ) : (
                                        <button type="button" className="btn btn-primary my-4" onClick={() => signin()}>Sign in</button>
                                    ) }
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <a href="kp" className="text-light"><small>Forgot password?</small></a>
                        </div>
                        <div className="col-6 text-right">
                            <small className="text-light cursor-pointer" onClick={() => props.showSignup()}>Create new account</small>
                        </div>
                    </div>
                </div>
            </div>
		</>
	)
}

export default SignUpCard
