import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import {
	Card,
	CardImg,
	Button
} from "reactstrap"

import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header"
import UseBottomNav from "components/Navigation/BottomNav"
import UseMyPartyCard from "components/Cards/MyPartyCard"
import axios from 'axios'

import showAlert from '../components/Alert'

const Home = () => {

	const [loading, setLoading] = useState(false)
	const [recentParties, setRecentParties] = useState([])

	useEffect(() => {
		const getTickets = () => {
			if(localStorage.getItem('jwt')) {
				axios.get(process.env.REACT_APP_BACKEND_URL + 'ticket/all', {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('jwt')}`
					}
				}).then((resp) => {
					console.log('updated')
					setRecentParties(resp.data.tickets)
				}).catch(() => {
					
				})
			}
		}

		window.addEventListener('focus', getTickets)

		if(document.hasFocus()) {
			getTickets()
		}

		return () => {
			window.removeEventListener('focus', getTickets)
		}
	}, [loading])

	const loadExtensionWindow = () => {
		window.open('https://chrome.google.com/webstore/detail/bingeask-watch-together/ijhfpmokhfibogihpnlafeadgobmhfhc', 'chromeStore', 'height=750,width=1024,left=350,top=100')

		window.onfocus = function () {
			window.location.reload()
		}
	}

	const ticketCreateAndStart = (url) => {
		// Get ticket ID to host on
		axios.get(process.env.REACT_APP_BACKEND_URL + 'ticket', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('jwt')}`
			}
		}).then((resp) => {
			// Call function at inject.js
			window.postMessage({ message: 'openPartyWindows', url, ticketId: resp.data.ticketId, jwt: localStorage.getItem('jwt') })
			setLoading(false)
		}).catch(() => {
			setLoading(false)
		})
	}

	const sessionCheck = (url) => {
		setLoading(true)
		// Check for session
		let jwt = localStorage.getItem('jwt')
		if (jwt) {
			axios.get(process.env.REACT_APP_BACKEND_URL + 'user/me', {
				headers: {
					Authorization: `Bearer ${jwt}`
				}
			}).then((resp) => {
				ticketCreateAndStart(url)
			}).catch((err) => {
				console.log(err)
				// Unauthenticated user
				localStorage.removeItem('jwt')
				localStorage.removeItem('userId')
				localStorage.removeItem('userName')
				showAlert('danger', <b>You are signed out, please try again.</b>)
				window.location.href = "profile"
			})
		} else {
			// Create guest user
			axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/createGuestUser').then((resp) => {
				localStorage.setItem('jwt', resp.data.token)
				localStorage.setItem('userId', resp.data.user.userId)
				localStorage.setItem('userName', resp.data.user.userName)
				ticketCreateAndStart(url)
			}).catch(() => {
				setLoading(false)
			})
		}
	}

	const rejoinParty = (ticketId, currentUrl) => {
		window.postMessage({ message: 'openPartyWindows', url: currentUrl, ticketId: ticketId, jwt: localStorage.getItem('jwt') })
	}

	return (
		<>
			<Helmet>
				<title>bingeAsk - Connecting Cinematic Souls Through Shared Experiences!!</title>
			</Helmet>

			{loading && (
				<div className="loader-overlay">
					<div className="loader-container">
						<div className="loader"></div>
					</div>
				</div>
			)}

			<div className={`mt-4 ${loading ? 'blur' : ''}`}>
				<div className="text-center">
					<UseHeader />
				</div>
				<div className="container">
					<div className="text-center mt-3">
						<Button className="btn-icon btn-3 shadow-none" id="install-extension-btn" color="danger" type="button" onClick={() => loadExtensionWindow()}>
							<span className="btn-inner--icon">
								<i className="fa fa-puzzle-piece" />
							</span>
							<span className="btn-inner--text">Install Extension</span>
						</Button>
					</div>
					{ recentParties.length !== 0 ? (
						<div className='mt-4' style={{ height: '20vh' }}>
							<h4 className='text-white text-center font-weight-500'>Recent Watch Party 🎉</h4>
							<div className="row justify-content-center mt-4">
								{ recentParties.map((recentParty, i) => (
									<div className="col-md-4 col-12" key={i}>
										<UseMyPartyCard id={ recentParty.ticket_id } name={ `Started ${moment(recentParty.date_added).format('ll')}` } url={recentParty.current_url} rejoinParty={rejoinParty} />
									</div>
								)) }
							</div>
						</div>
					) : (
						<div style={{ height: '0vh' }}></div>
					) }
					<p className="font-weight-500 text-center text-light-gray my-4" style={{ fontSize: 13 }}>Select Streaming Platform</p>
					<div className="row justify-content-center" id="platform-div" style={{ alignContent: 'flex-start' }}>
						<div className="col-6 col-md-3 mb-3">
							<Card className="bg-dark cursor-pointer" onClick={() => sessionCheck("https://youtube.com")}>
								<CardImg
									alt="Watch Together - bingeAsk"
									src={require("../assets/img/ott-icons/YouTube.webp")}
									className="img-fluid"
									rel="preload"
									type="image/webp"
									width="600" height="400"
								/>
							</Card>
						</div>
						<div className="col-6 col-md-3 mb-3">
							<Card className="bg-dark cursor-pointer" onClick={() => sessionCheck("https://netflix.com")}>
								<CardImg
									alt="Watch Netflix Together"
									src={require("../assets/img/ott-icons/netflix-bg.webp")}
									className="img-fluid"
									rel="preload"
									type="image/webp"
									width="600" height="400"
								/>
							</Card>
						</div>
						<div className="col-6 col-md-3 mb-3 text-center">
							<Card className="bg-dark cursor-pointer mb-2" onClick={() => sessionCheck("https://www.primevideo.com/")}>
								<CardImg
									alt="Watch Amazon Prime Videos Together"
									src={require("../assets/img/ott-icons/prime-video-bg.webp")}
									className="img-fluid gray-logo"
									rel="preload"
									type="image/webp"
									width="600" height="400"
								/>
							</Card>
							<small className="text-white">Coming Soon</small>
						</div>
						<div className="col-6 col-md-3 mb-3 text-center">
							<Card className="bg-dark cursor-pointer mb-2" onClick={() => sessionCheck("https://www.hotstar.com/")}>
								<CardImg
									alt="Watch Together - bingeAsk"
									src={require("../assets/img/ott-icons/disney-bg.webp")}
									className="img-fluid gray-logo"
									rel="preload"
									type="image/webp"
									width="600" height="400"
								/>
							</Card>
							<small className="text-white">Coming Soon</small>
						</div>
						<div className="col-6 col-md-3 mb-3 text-center">
							<Card className="bg-dark cursor-pointer mb-2" onClick={() => sessionCheck("https://www.hotstar.com/")}>
								<CardImg
									alt="Watch Together - bingeAsk"
									src={require("../assets/img/ott-icons/HBO-max-banner.webp")}
									className="img-fluid gray-logo"
									rel="preload"
									type="image/webp"
									width="600" height="400"
								/>
							</Card>
							<small className="text-white">Coming Soon</small>
						</div>
						<div className="col-6 col-md-3 mb-3 text-center">
							<Card className="bg-dark cursor-pointer mb-2" onClick={() => sessionCheck("https://www.hotstar.com/")}>
								<CardImg
									alt="Watch Together - bingeAsk"
									src={require("../assets/img/ott-icons/Crunchyroll.webp")}
									className="img-fluid gray-logo"
									rel="preload"
									type="image/webp"
									width="600" height="400"
								/>
							</Card>
							<small className="text-white">Coming Soon</small>
						</div>
					</div>
					<div className="section py-7">
						<div className="row justify-content-center align-items-center">
							<div className="col-md-7 col-12">
								<img alt="bingeAsk - watch together" src={require("../assets/img/brand/theatre.webp")} className="img-fluid mt-3 mb-3" width="600" height="400" />
							</div>
							<div className="col-md-5 col-12">
								<h2 className="text-s font-weight-700">Watch Netflix, YouTube, Amazon Prime with your friends</h2>
								<p className="text-light-gray fs-14 mt-4 mb-0">
									Host a Netflix watch party and invite or find friends to watch together. Watch shows and movies with video chat and start binge-watching - it's screen time!
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="section bg-dark py-7">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 text-center">
								<h2 className="font-weight-700 text-white">How to watch Netflix together on bingeAsk?</h2>
							</div>
						</div>
						<div className="row mt-1">
							<div className="col-md-4 col-12 text-center">
								<div className="card shadow-none bg-a mt-4">
									<div className="card-body">
										<h3 className="font-weight-700 text-dark">Host Watch Party</h3>
										<h4 className="font-weight-500 small text-dark">Choose Netflix from the list of platforms on bingeAsk to host a Netflix party. Copy the party link and share it with your friends.</h4>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12 text-center">
								<div className="card shadow-none bg-s mt-4">
									<div className="card-body">
										<h3 className="font-weight-700 text-dark">Watch Movies and Shows</h3>
										<h4 className="font-weight-500 small text-dark">Choose the movie or show you want to watch and bingeAsk will automatically play, start syncing for your party in real time.</h4>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12 text-center">
								<div className="card shadow-none bg-k mt-4">
									<div className="card-body">
										<h3 className="font-weight-700 text-dark">Chat It Out</h3>
										<h4 className="font-weight-500 small text-dark">Spill the beans with text or face-to-face video chat. Watch movie together and share the vibe side by side, even if miles apart.</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<UseBottomNav page='home' />

				<div style={{ marginBottom: '14vh' }}>
					<UseFooter />
				</div>
			</div>
		</>
	)
}

export default Home
