import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
    CardBody
} from "reactstrap";

import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header";
  
  const TermsAndConditions = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Terms And Conditions</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container">
                    <div className="row py-4">
                        <div className="col-sm-12 col-md-12">
                            <Card className="bg-dark">
                                <CardBody className="bg-dark p-5">
									<h1 className="font-weight-700 text-light-gray mb-3" style={{fontSize: '20px'}}>This website is an electronic publication of and a service provided by bingeAsk.</h1>
									<small className="text-light-gray mb-0">
									Any and all use of this website is subject to, and constitutes acknowledgment and acceptance of, the following terms ("Terms of Use").<br />bingeAsk may amend these Terms of Use at any time by posting the amended version on this site. Such amended version shall automatically be effective upon its posting on this site.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Definitions</h1>
									<small className="text-light-gray mb-0">
									In these Terms of Use, the following words and expressions shall, unless the context otherwise requires, have the following respective meanings:-
									<br/><br/>
									"Force Majeure Event" in relation to a person or entity shall mean an event beyond that person's or entity's reasonable control, including but not limited to: acts of God; fire; flood; explosion; storm; disaster; vandalism; power surge or outage; cable cut; disruptions or failures in telecommunication systems or equipment or electrical power or supply; server crashes; back-up failures; disruptions to or failure of Internet access or connectivity; serious accidents; epidemics or quarantine restrictions; any law, order, regulation, direction, action or request of any government (including state and local governments) or any civil or military authority, or of any dependent agency, commission, court, bureau, corporation or other instrumentality thereof; allocation regulations or orders affecting materials and/or the supply thereof; national emergencies, insurrections, riots, civil commotion, war or warlike operations; strikes, lockouts, work stoppages or labour disputes, troubles or difficulties; delays in transportation; supplier failures, shortages, breaches or delays; or inability after due and timely diligence to procure materials, accessories, equipment or parts.
									<br/><br/>
									"Specific Services" shall mean those services, provided by relevant members of bingeAsk or through this website, which are subject to other specific terms and conditions in addition to these Terms of Use.
									<br/><br/>
									"Specific Terms" shall mean the respective terms and conditions applicable to Specific Services, including those prescribed or imposed by relevant members of bingeAsk in relation to such Specific Services, or those contained in respective agreements entered into between Visitors and relevant members of the bingeAsk in relation to such Specific Services.
									<br/><br/>
									"Third Party Content" shall mean those content or materials (including text and images) in this website the titles, rights and interests in or to which are not owned by members of the bingeAsk but by the respective Third Party Licensors.
									<br/><br/>
									"Third Party Licensors" shall mean the respective owners of the titles, rights and interests in or to the Third Party Content.
									<br/><br/>
									"Visitor" shall mean the individual who, or the entity whose agent, accesses or uses this website, the services provided in this website, or any of the Overall Content.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Application of other terms and conditions</h1>
									<small className="text-light-gray mb-0">
									These Terms of Use shall apply to each Specific Service, in addition to any and all other Specific Terms applicable to such Specific Service; provided however that:-
									<br/><br/>
									In the event of any conflict or inconsistency between any provision of the Specific Terms and any provision of these Terms of Use, such conflict or inconsistency shall (except as otherwise expressly provided or agreed) be resolved in a manner favourable to bingeAsk; and only to the extent that such conflict or inconsistency cannot be so resolved, the provisions of the Specific Terms shall prevail.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Intellectual Property</h1>
									<small className="text-light-gray mb-0">
									Unless otherwise expressly stated<br/>
		All titles, rights and interests (including without limitation copyright) in respect of the Global Sources Content belong to bingeAsk and nothing herein confers on the Visitor any title, right or interest in respect thereof; and all titles, rights and interests (including without limitation copyright) in respect of Third Party Content belong to the respective Third Party Licensor, and nothing herein confers on the Visitor any title, right or interest in respect thereof.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Modifications</h1>
									<small className="text-light-gray mb-0">
									The bingeAsk reserves the right to alter or modify this website, including portions thereof, from time to time. Such alterations or modifications may include, without limitation, addition or withdrawal of features, services, data or content or changes in instructions. The bingeAsk may also at any time suspend or discontinue all or any part of this website or the provision of updates.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>No Endorsement of Third Parties</h1>
									<small className="text-light-gray mb-0">
									The bingeAsk does not endorse or make any representation or warranty whatsoever (whether express or implied), and shall not be responsible or liable under any circumstances whatsoever, in respect of :-
		<br/><br/>
		any third party website hyperlinked to this website, or any products, services, information, data or other content offered or provided therein; and/or
		any product, service, information, data or other content offered or provided by third parties in or through this website. Such third party websites and such third party products, services, information, data or other content (whether provided or offered therein or in or through this website) are the sole responsibility of independent third parties, and as against the bingeAsk, the Visitor's use thereof or reliance thereon is solely at the Visitor's own risk. A Visitor's rights and remedies in respect thereof are governed solely by other applicable agreements or terms (if any) between the Visitor and the relevant third party.

									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Without prejudice to the generality of paragraph 8(a) above</h1>
									<small className="text-light-gray mb-0">
									A description or reference to a product or service in this website (including any description or reference via hyperlink) does not imply endorsement by any member of the bingeAsk of that product or service; the bingeAsk does not make any representation or warranty as to the quality, accuracy, reliability or suitability of any third party websites or any third party products, services, information, data or other content (whether provided or offered therein or in or through this website); and the bingeAsk shall not be liable or responsible for any defects, deficiencies, errors, omissions or inaccuracies in any third party websites or any third party products, services, information, data or other content (whether provided or offered therein or in or through this website).


									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Exculsion Of Representation And Warranties</h1>
									<small className="text-light-gray mb-0">
									This website and the overall content are made available "as is" without any representation or warranty whatsoever, whether express or implied, including but not limited to any representation or warranty as to their performance, merchantability or fitness for any particular purpose or use; and all representations and/or warranties, to the extent capable of being excluded under applicable law, are hereby expressly excluded by the bingeAsk, its licensors, dealers, distributors, agents and employees, and all third parties (if any) involved in the creation, production or delivery of this website and/or the overall content.
									<br/><br/>
		In particular, but without prejudice to the generality of the foregoing, the bingeAsk, its licensors, dealers, distributors, agents and employees, and third parties (if any) involved in the creation, production or delivery of this website and/or the overall content, make no representations or warranties as to, and the visitor assumes the entire risk as to:-
		<br/><br/>
		anything contained in, the performance of, or the results that may be obtained by using, this website or any of the overall content;
		the consequences of downloading any attachment transmitted through this website
		the quality, accuracy, reliability or suitability for any purpose whatsoever of, this website or any of the overall content; and/or
		the standing, credit or otherwise of any person or entity mentioned in this website or in any of the overall content.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Exculsion Of Liability</h1>
									<small className="text-light-gray mb-0">
									In no event shall bingeAsk, its licensors, dealers, distributors, agents or employees, or any third party involved in the creation, production or delivery of this website or any of the overall content be liable for:-
		<br/><br/>
		any direct, indirect, special, incidental, secondary, consequential, punitive or exemplary damages arising out of the use of or inability to use this website or any of the overall content (such as but not limited to loss of data, loss of business information, business interruption or delay, or loss of anticipated revenue, profits or benefits, or any economic or financial loss), whether based upon contract, tort, negligence, strict liability or otherwise, and even if they were aware of, were advised of, or ought to have known of, the possibility of such damages; any consequences of downloading or using any attachment transmitted through this website; any failure, delay, disruption or interruption in, of or to this website, howsoever caused or arising; any failure, delay, disruption or interruption in, of or to the transmission or reception of any data or information through this website, howsoever caused or arising; any defect, deficiency, breakdown or failure of any software or any equipment or system (whether or not maintained or operated by the global sources group or any other person), howsoever caused or arising; any loss, corruption, deletion or damage of or to any data or information (whether belonging to, provided by or stored by the visitor or otherwise) which is transmitted to or stored in any system or equipment (whether or not maintained or operated by the global sources group, the visitor or any other person), howsoever caused or arising; any breach, failure of performance or delay caused by a force majeure event; and/or any defects, deficiencies, errors, omissions or inaccuracies in this website or in any of the overall content.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Limitation of liability above</h1>
									<small className="text-light-gray mb-0">
									Subject always to the express exclusions in these terms of use, the liability of tml, other members of the bingeAsk, their licensors, dealers, distributors, agents or employees, or third parties (if any) involved in the creation, production or delivery of this website or any of the overall content, shall in no event exceed in the aggregate the sum of one united states dollar (us$1.00) only, whether in contract, tort (including negligence or breach of statutory duty) or otherwise, or for any loss, damage or liability whatsoever.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Entire Agreement</h1>
									<small className="text-light-gray mb-0">
									These Terms of Use, together with any other Specific Terms relating to Specific Services (if applicable), constitute the entire agreement between the parties with respect to the subject matter hereof and thereof, and supersede any and all prior written and/or oral representations, understandings, agreements or communications between the parties regarding the subject matter hereof and thereof.
		<br/><br/>
		The provisions and terms of any purchase order or other instrument issued by the Visitor shall be of no effect and the acceptance of any such order or instrument shall not in any way extend or alter these Terms of Use (or any other Specific Terms relating to Specific Services, if applicable).
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Waivers</h1>
									<small className="text-light-gray mb-0">
									Failure of either party to insist at any time upon strict compliance with any provision herein or to seek remedy of any breach shall not constitute or be construed as a waiver of such provision or breach, or of any other rights or remedies.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Illegality</h1>
									<small className="text-light-gray mb-0">
									If any provision of these Terms of Use is or becomes illegal, invalid or unenforceable ("Affected Provision"), such Affected Provision shall, to the maximum extent possible, be modified and/or replaced by an equivalent legal, valid and enforceable provision in a manner which accords with or which gives effect to, as closely as possible, the original intent of the Affected Provision. If, however, such modification or replacement is not permissible, then the Affected Provision (or the modification, replacement or severance thereof) shall be deemed to be severed from these Terms of Use. The illegality, invalidity or unenforceability of the Affected Provision shall not in any way affect the legality, validity or enforceability of the other provisions of these Terms of Use, which shall remain in full force and effect.
									</small>

									<h1 className="font-weight-700 text-light-gray mb-3 mt-4" style={{fontSize: '20px'}}>Governing Law and Juridiction</h1>
									<small className="text-light-gray mb-0">
									These Terms of Use shall be construed in accordance with and governed by the laws of India. The Visitor hereby submits to the non-exclusive jurisdiction of the courts of India and/or such other courts of competent jurisdiction as bingeAsk may in its absolute discretion deem fit.
									</small>
								</CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default TermsAndConditions;
  