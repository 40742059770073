import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
	CardImg,
    CardBody
} from "reactstrap";

import UseFooter from "components/Footers/Footer";
import UseHeader from "components/Headers/Header";
  
  const TrendingShows = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Trending Shows</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container">
                    <div className="row py-4">
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/animal.jpeg")}
                                        className="img-fluid h-100"
                                        width="600" height="400"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">Unlikely Animal Friends</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/12th-fail.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">12th Fail</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/leo.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">Anime Gataris</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/killer.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">Killer of the flowre moon</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/oppenhimer.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">Oppenhiemer</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/fas--charlie.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">Fast charlie</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-4">
                            <Link to={'../show-details'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/the-archies.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h5 className="text-light-gray mb-0 text-ellipsis">The Archies</h5>
                                        <small className="text-gray">IMDb 9.2/10- 2h 27m</small>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                    </div>
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default TrendingShows;
  