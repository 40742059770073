import {
	Card,
	CardImg,
    Button
} from "reactstrap";

const FindFriendCard = (props) => {
	return (
		<>
			<div className="card bg-dark border-0" style={{zIndex: 1}}>
                <div className="card-body p-3">
                    <div className="row align-items-center">
                        <div className="col-auto pr-0">
                            <CardImg
                                alt="Watch Together - bingeAsk"
                                src={process.env.REACT_APP_PFP_URL + props.pfp}
                                rel="preload"
                                type="image/webp"
                                style={{
                                    objectFit: 'cover',
                                    height: '45px',
                                    width: '45px'
                                }}
                            />
                        </div>
                        <div className="col">
                            <h5 className="text-white font-weight-500 mb-0">{ props.name }</h5>
                        </div>
                        <div className="col-auto text-right">
                            <Button className="font-weight-400 text-s border-dark" size="sm" type="button" style={{backgroundColor: '#1c1c21', fontSize: '10.5px'}}>
                                Message
                            </Button>
                        </div>
                    </div>
                    <div className="row px-2 mt-4">
                        { props.favs.map((fav, i) =>
                            <div className="col px-1" key={i} style={{maxWidth: 83}}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={"https://image.tmdb.org/t/p/original/" + fav}
                                        className="img-fluid h-100"
                                        width="600" height="400"
                                        rel="preload"
                                        type="image/webp"
                                    />
                                </Card>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
		</>
	)
}

export default FindFriendCard
