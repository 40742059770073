import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { UncontrolledAlert } from 'reactstrap'

const DynamicAlert = ({ type, text }) => {
    const [width, setWidth] = useState('100%')

    useEffect(() => {
        let current = 100
        setInterval(() => {
            current -= 0.1
            setWidth(current + '%')
        }, 5)
    }, [])

    return (
        <UncontrolledAlert color={type} fade={false} className="mb-0 rounded-0 w-100 p-0" style={{ zIndex: 2, position: 'fixed', bottom: '0' }}>
            { type === 'danger' ? (
                <div style={{ borderTop: '3px solid #fbbac6', width, marginTop: '-1.2px' }}></div>
            ) : null }
            { type === 'primary' ? (
                <div style={{borderTop: '3px solid #c6cbf2', width, marginTop: '-1.2px'}}></div>
            ) : null }
            { type === 'success' ? (
                <div style={{borderTop: '3px solid #bdebd5', width, marginTop: '-1.2px'}}></div>
            ) : null }
            <div className="p-3">
                <span className="alert-inner--text">
                    {text}
                </span>
            </div>
        </UncontrolledAlert>
    )
}

const showAlert = (type, text) => {
    let alertContainer = document.getElementById('alert-container')

    if (!alertContainer) {
        alertContainer = document.createElement('div')
        alertContainer.id = 'alert-container'
        document.body.appendChild(alertContainer)
    }

    const alertWrapper = document.createElement('div')

    ReactDOM.render(<DynamicAlert type={type} text={text} />, alertWrapper)

    alertContainer.appendChild(alertWrapper)

    setTimeout(() => {
        ReactDOM.unmountComponentAtNode(alertWrapper)
        alertContainer.removeChild(alertWrapper)
    }, 5000)
}

export default showAlert