import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet'

import {
	Card,
	CardImg
} from "reactstrap";

import UseFooter from "components/Footers/Footer";
import UseHeader from "components/Headers/Header";
  
  const OurStory = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Our Story</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container rounded">
                    <div className="py-4" style={{zIndex: 1}}>
                        <div className="row">
                            <div className="col-sm-12 col-md-3 mb-3">
                                <Card style={{position: 'sticky', top: 40}}>
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/brand/our-story.webp")}
                                        className="img-fluid h-100"
                                        width="500" height="300"
                                        rel="preload"
                                        type="image/webp"
                                    />
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-9">
                                <div className="mb-4">
                                    <h3 className="text-s font-weight-700 mb-2">The Idea <span className="text-light-gray fs-12 small">June 12, 2022</span></h3>
                                    <p className="text-white small">Idea is started with finding a partner for movie night. One night I was waking up till late night, it was almost 4:00am in the moring and I thought to watch netflix. But I used to watch netflix with my friend but by the time she was sleeping at her home, so I thought let me google if I'll find someone else to watch together so I searched "find partner to watch movies together".</p>
                                </div>

                                <div className="mb-4">
                                    <h3 className="text-s font-weight-700 mb-2">The Discussion <span className="text-light-gray fs-12 small">June 12, 2022</span></h3>
                                    <p className="text-white small"> When I searched unfortunatly or I should say fortunatly I did not find any result where I can I find friend or partner to watch movie together. I felt bad and excitment at the same time, I shared this story with my friend and he said nice let's do some research on it and let's see if we find anything related to it.</p>
                                </div>

                                <div className="mb-4">
                                    <h3 className="text-s font-weight-700 mb-2">The Research <span className="text-light-gray fs-12 small">June 12, 2022</span></h3>
                                    <p className="text-white small"> After our discussion we tried to find platforms like this where we can find partner to watch movie together. We found some where we can watch together but can't find friends for movie night. And that was the day we thought to build this platform, but before that we decided to ask few peoples so we talked with our friend... we conducted some interviews and finally here we are!</p>
                                </div>

                                <div className="mb-4">
                                    <h3 className="text-s font-weight-700 mb-2">The Prototype <span className="text-light-gray fs-12 small">July 09, 2022</span></h3>
                                    <p className="text-white small"> After the interviews and discussion we started working on design, color palettes and prototyping. We created few screens and shared with friends and interviewd with some more people but this time with prototype. After getting feedback on prototype we started improving and developing the product.</p>
                                </div>

                                <div className="mb-4">
                                    <h3 className="text-s font-weight-700 mb-2">The Domain <span className="text-light-gray fs-12 small">August 12, 2022</span></h3>
                                    <p className="text-white small"> Here we come on to deciding a cool name for our plateform 😎. I asked my co-founder for name suggestions, here what he has suggested -<br/><br/>
                                        <li>Flix Lifestyle?</li>
                                        <li>Movie Dream / Dream Movie</li>
                                        <li>Ticket flix</li>
                                        <br/>
                                        Before deciding a name we used to call our platform "bingeAsk" and after all the discussion on name we decided to keep bingeAsk as our final brand name.<br/>On 15th August 2022 we registered our domain name as "bingeask.com" 🥳. Meaning of bingeAsk is - "Ask people for binge watch"
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-sm-12 col-md-3 mb-3">
                                <Card className="page-header header-filter clear-filter" style={{backgroundImage: `url(${Background})`,position: 'sticky', top: 40, backgroundSize: 'contain'}}>
                                    <CardBody style={{zIndex: 2}}>
                                        <FormGroup className="rounded-0 mb-3" style={{marginTop: 45}}>
                                            <Input placeholder="Write your name" type="text" className="join-us-form-control"/>
                                        </FormGroup>
                                        <div className="text-center">
                                            <h5>Yashwardhan</h5>
                                            <Badge className="badge bg-primary"> Joined since 2021</Badge>
                                            <hr className="my-3" />
                                            <h6>Khushboo</h6>
                                            <h6>Sumit</h6>
                                            <h6>Hema</h6>
                                            <h6>Kawalpreet</h6>
                                            <h6>Shivam</h6>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="container mt-5 mb-5">
                
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-12 col-12">
                            <h2 className="text-s font-weight-700 text-center">Why another watch together platform?</h2>
                            <p className="text-light-gray fs-14 mt-4 mb-0 text-center">
                                Hey there movie/show buffs and chill seekers! 🍿 Imagine this: You're craving a movie night, but your usual squad is scattered. No worries, hit bingeAsk! A platform to find friends or invite your own gang for an epic watch party. 🎥💻 Whether it's the latest Netflix binge or a casual YouTube browsing, bingeAsk gives you complete virtual hangout experience. 🌐✨ Find/search friends, hit play, and enjoy movies with video chat in sync.<br /> So why wait? Grab your popcorn, sign up, and let the Bingeask adventures begin! 🎊
                            </p>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <Card className="bg-dark">
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WeMJo701mvQ?si=uDpGLuzJKvdB1ypd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </Card>
                        </div>
                    </div> */}
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default OurStory;
  