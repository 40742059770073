import { useState, useEffect } from "react"
import {
    Card,
    CardImg,
    CardBody,
    Button,
    Modal,
    Input
} from "reactstrap"
import axios from "axios"
import showAlert from '../Alert'

export const AddShowModal = (prop) => {
    const [searchKey, setSearchKey] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchResults, setSearchResults] = useState([])

    useEffect(() => {
        if (searchKey.length >= 1) {
            setLoading(true)
            const timerId = setTimeout(() => {
                searchShow(searchKey)
            }, 1000)

            return () => clearTimeout(timerId)
        } else {
            setSearchResults([])
        }
    }, [searchKey])

    const searchShow = (searchKey) => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BACKEND_URL}show/search?q=${searchKey}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        }).then((resp) => {
            setSearchResults(resp.data.result)
            setLoading(false)
        }).catch((err) => {
            console.error('Error fetching data:', err)
            setLoading(false)
        })
    }

    const toggleShow = (tmdbId, type, add) => {
        axios.post(`${process.env.REACT_APP_BACKEND_URL}user/me/show`, {
            tmdbId,
            type,
            add
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        if(add) {
            showAlert('success', 'Show added to your list')
        } else {
            showAlert('primary', 'Show removed from your list')
        }
        setSearchResults((searchResults) => {
            return searchResults.map((result) =>
                result.id === tmdbId ? { ...result, selected: add } : result
            )
        })
    }

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={prop.modalOpen}
            toggle={() => prop.setModalOpen(!prop.modalOpen)}
        >
            <div className="modal-header align-items-center">
                <div className="form-group mb-0">
                    <div className="input-group w-150">
                        <div className="input-group-prepend">
                            <span className="input-group-text bg-dark border-0"><i className="fa fa-search"></i></span>
                        </div>
                        <Input className="form-control bg-dark border-0" value={searchKey} placeholder="Search show by name" onInput={(e) => setSearchKey(e.target.value)} />
                    </div>
                </div>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => prop.setModalOpen(!prop.modalOpen)}
                >
                    <span className="text-gray" aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body pt-0" style={{ height: '68vh', overflow: 'auto' }}>
                { loading ? (
                    <div className="row justify-content-center align-items-center px-2 mt-3" style={{height: '50vh'}}>
                        <div className="col-12 text-center">
                            <span className="spinner-border spinner-border-lg text-s" role="status" aria-hidden="true"></span>
                        </div>
                    </div>
                ) : (
                    <div className="row px-2 mt-3">
                        {searchResults.length === 0 ? (
                            null
                        ) : (
                            searchResults.map((searchResult, i) => (
                                <div className="col-4 col-md-1 px-2 mb-4" key={i}>
                                    <Card className="bg-dark">
                                        <CardBody className="bg-dark p-2">
                                            <div className="custom-control custom-checkbox p-absolute">
                                                <input
                                                    className="custom-control-input"
                                                    id={`customCheck${i}`}
                                                    type="checkbox"
                                                    checked={searchResult.selected}
                                                    onChange={(e) => toggleShow(searchResult.id, searchResult.type, e.target.checked)}
                                                />
                                                <label className="custom-control-label text-gray" htmlFor={`customCheck${i}`}>
                                                </label>
                                            </div>
                                        </CardBody>
                                        <CardImg
                                            alt="Watch Together - bingeAsk"
                                            src={"https://image.tmdb.org/t/p/original/" + searchResult.poster_path}
                                            className="img-fluid h-100"
                                            width="600" height="400"
                                            rel="preload"
                                            type="image/webp"
                                        />
                                    </Card>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    outline
                    className="border-0 text-gray"
                    onClick={() => prop.setModalOpen(!prop.modalOpen)}
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}