import {
	CardImg,
    Button
} from "reactstrap";

const ChatHeaderCard = (props) => {
	return (
		<>
			<div className="row align-items-center mb-3">
                <div className="col-auto pr-0">
                    <CardImg
                        alt="Watch Together - bingeAsk"
                        src={"https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=1445"}
                        rel="preload"
                        height="35"
                        width="35"
                        type="image/webp"
                    />
                </div>
                <div className="col">
                    <h5 className="text-white font-weight-400 mb-0">Yash</h5>
                </div>
                <div className="col-auto text-right">
                    <Button className="font-weight-400 text-a border-dark" size="sm" type="button" style={{backgroundColor: '#1c1c21', fontSize: '14px'}}>
                    <i class="fa fa-video"></i>
                    </Button>
                </div>
            </div>
		</>
	)
}

export default ChatHeaderCard
