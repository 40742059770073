import { NavLink as Link } from "react-router-dom"

const Footer = () => {
  	return (
		<footer className="footer bg-dark pt-lg text-left">
			<div className="container">
				<div className="row">
					<div className="col-md-3 mb-4 ms-auto">
						<div>
							<img src={require("assets/img/logo/bingeAsk-light-full.webp")} className="mb-3 img-fluid h-3" alt="main_logo" width="160" height="300" />
						</div>
						{/* <div className="row">
							<div className="col-sm-6 col-md-6 col-6">
								<a href="">
								<img src={require("assets/img/icons/app-store.webp")} className="mb-3 img-fluid" alt="main_logo" />
								</a>
							</div>
							<div className="col-sm-6 col-md-6 col-6">
								<a href="">
								<img src={require("assets/img/icons/play-store.webp")} className="mb-3 img-fluid" alt="main_logo" />
								</a>
							</div>
						</div> */}
					</div>
					<div className="col-md-1 mb-4 ms-auto"></div>
					<div className="col-md-2 col-sm-6 col-6 mb-4">
						<div>
							<h6 className="fw-500 text-light-gray" style={{fontSize: '.83rem'}}>Watch Parties</h6>
							<ul className="flex-column nav">
								<li className="nav-item">
									<Link to={'../netflix'} className="nav-link pl-0 text-capitalize fs-12">
										Netflix Watch Party
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md-2 col-sm-6 col-6 mb-4">
						<div>
							<h6 className="fw-500 text-light-gray" style={{fontSize: '.83rem'}}>Company</h6>
							<ul className="flex-column nav">
								<li className="nav-item">
									<Link to={'../'} className="nav-link pl-0 text-capitalize fs-12">
										Home
									</Link>
								</li>
								<li className="nav-item">
									<Link to={'../blog'} className="nav-link pl-0 text-capitalize fs-12">
										Blog
									</Link>
								</li>
								<li className="nav-item">
									<Link to={'../our-story'} className="nav-link pl-0 text-capitalize fs-12">
										Our Story
									</Link>
								</li>
								{/* <li className="nav-item">
									<Link to={'../trending-shows'} className="nav-link pl-0 text-capitalize fs-12">
										Trending Shows
									</Link>
								</li> */}
							</ul>
						</div>
					</div>
					<div className="col-md-2 col-sm-6 col-6 mb-4 me-auto">
						<div>
							<h6 className="fw-500 text-light-gray" style={{fontSize: '.83rem'}}>Legal</h6>
							<ul className="flex-column ms-n3 nav">
								<li className="nav-item">
									<Link to={'../terms-and-conditions'} className="nav-link pl-0 text-capitalize fs-12" aria-label="bingeAsk Terms and Condition Link">
										Terms and Conditions
									</Link>
								</li>
								<li className="nav-item">
									<Link to={'../privacy-policy'} className="nav-link pl-0 text-capitalize fs-12" aria-label="bingeAsk Privacy Policy Link">
										Privacy Policy
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md-2 col-sm-12 col-12 mb-4">
						<div>
							<h6 className="fw-500 text-light-gray" style={{fontSize: '.83rem'}}>Follow, Like &amp; Join</h6>
						</div>
						<ul className="d-flex flex-row pl-0" style={{listStyle: 'none'}}>
							<li className="nav-item instagram">
								<Link to={'https://www.instagram.com/bingeask/'} className="nav-link pl-0 instagram" target="_blank" rel="noreferrer" aria-label="Instagram Link">
									<i className="fa-brands fa-instagram instagram-hover"></i>
								</Link>
							</li>
							<li className="nav-item">
								<Link to={'https://facebook.com/bingeask'} className="nav-link pl-0 pe-1 discord" target="_blank" rel="noreferrer" aria-label="Discord Link">
									<i className="fa-brands fa-facebook discord-hover"></i>
								</Link>
							</li>
							<li className="nav-item">
								<Link to={'https://www.linkedin.com/company/bingeask'} className="nav-link pl-0 pe-1 telegram" target="_blank" rel="noreferrer" aria-label="Telegram Link">
									<i className="fa-brands fa-youtube telegram-hover"></i>
								</Link>
							</li>

							{/* <li className="nav-item">
								<a className="nav-link pl-0 pe-1" href="https://www.facebook.com/bingeask/" target="_blank" rel="noreferrer">
								<i className="fa-brands fa-facebook"></i>
								</a>
							</li> */}
							{/* <li className="nav-item">
								<a className="nav-link pl-0 pe-1" href="https://www.linkedin.com/company/bingeask" target="_blank" rel="noreferrer">
								<i className="fa-brands fa-linkedin"></i>
								</a>
							</li> */}
						</ul>
					</div>
					<div className="col-12 mt-4">
						<div className="text-center">
							<p className="text-light-gray mt-4 mb-0 font-weight-normal fs-12">
								All rights reserved. Copyright © <script>document.write(new Date().getFullYear())</script>2023 bingeAsk!
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
  	);
};

export default Footer;
