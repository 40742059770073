import { Helmet } from 'react-helmet'

import {
    Card,
    CardImg,
    Button
} from "reactstrap"

import UseHeader from "components/Headers/Header"
import { useParams } from "react-router-dom"
import { useEffect, useState } from 'react'
import axios from 'axios'
import showAlert from '../components/Alert'

const JoinParty = () => {

    const [ticketDetails, setTicketDetails] = useState({
        userName: '',
        ticketId: '',
        currentUrl: ''
    })

    const [loading, setLoading] = useState(true)

    const loadExtensionWindow = () => {
        window.open('https://chrome.google.com/webstore/detail/bingeask-watch-together/ijhfpmokhfibogihpnlafeadgobmhfhc', 'chromeStore', 'height=750,width=1024,left=350,top=100')

        window.onfocus = function () {
            window.location.reload()
        }
    }

    const { token } = useParams()

    const fetchTicket = (userId) => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `ticket/${token}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        }).then((resp) => {
            // We can't allow if user is with same user id
            if (resp.data.ticket.user_id == userId) { // eslint-disable-line eqeqeq
                showAlert('danger', 'You can not join your own party.')
            } else {
                setTicketDetails({
                    userName: resp.data.ticket.user_name,
                    ticketId: resp.data.ticket.ticket_id,
                    currentUrl: resp.data.ticket.current_url
                })
                setLoading(false)
            }
        }).catch((err) => {
            showAlert('danger', 'Unauthorized')
        })
    }

    // Get ticket by token
    useEffect(() => {
        // Get me
        const jwt = localStorage.getItem('jwt')
        if (jwt) {
            axios.get(process.env.REACT_APP_BACKEND_URL + 'user/me', {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            }).then((resp) => {
                // Fetch ticket
                fetchTicket(resp.data.user.user_id)
            }).catch((err) => {
                console.log(err)
                // Unauthenticated user
                localStorage.removeItem('jwt')
                localStorage.removeItem('userId')
                localStorage.removeItem('userName')
                showAlert('danger', 'You are signed out, please try again.')
                window.location.href = "profile"
            })
        } else {
            // Create guest user
            axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/createGuestUser').then((resp) => {
                localStorage.setItem('jwt', resp.data.token)
                localStorage.setItem('userId', resp.data.user.userId)
                localStorage.setItem('userName', resp.data.user.userName)
                fetchTicket(resp.data.user.userId)
            }).catch(() => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const joinParty = () => {
        window.postMessage({ message: 'openPartyWindows', url: ticketDetails.currentUrl, ticketId: ticketDetails.ticketId, jwt: localStorage.getItem('jwt') })
    }

    return (
        <>
            <Helmet>
                <title>Join {ticketDetails.userName.split(" ")[0]}'s Party on bingeAsk</title>
            </Helmet>
            <div className="mt-4">
                <div className="text-center">
                    <UseHeader />
                    <div className="text-center mt-4">
                        <Button className="btn-icon btn-3 shadow-none" id="install-extension-btn" color="danger" type="button" onClick={() => loadExtensionWindow()}>
                            <span className="btn-inner--icon">
                                <i className="fa fa-puzzle-piece" />
                            </span>
                            <span className="btn-inner--text">Install Extension</span>
                        </Button>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-sm-12 col-md-6">
                        <div className="bg-dark rounded my-5">
                            <div className="" style={{ zIndex: 1 }}>
                                <div className="row align-items-center">
                                    <div className="col-sm-12 col-md-3">
                                        <Card>
                                            <CardImg
                                                alt="Watch Together - bingeAsk"
                                                src={require("../assets/img/brand/join-party.webp")}
                                                className="img-fluid h-100"
                                                width="400" height="600"
                                                rel="preload"
                                                type="image/webp"
                                            />
                                        </Card>
                                    </div>
                                    <div className="col-auto my-3">
                                        <h3 className="text-white font-weight-700">Join Watch Party</h3>
                                        <h5 className="text-light-gray font-weight-400">Hosted by {ticketDetails.userName.split(' ')[0]}</h5>
                                        {loading ? (
                                            <Button className="btn-icon btn-3 shadow-none mt-3" color="primary" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Joining party...
                                            </Button>
                                        ) : (
                                            <Button className="btn-icon btn-3 shadow-none mt-3" color="primary" type="button" onClick={joinParty}>
                                                <span className="btn-inner--icon">
                                                    <i className="fa fa-video-camera" />
                                                </span>
                                                <span className="btn-inner--text">Join Party</span>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JoinParty;
