import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
	CardImg,
    Button
} from "reactstrap";

import UseFooter from "components/Footers/Footer";
import UseHeader from "components/Headers/Header";
  
  const ShowDetails = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Show Details</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center mb-4">
                    <UseHeader />
					<Link to={'../trending-shows'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container page-header header-filter clear-filter purple-filter rounded" style={{backgroundImage: "url('https://www.themoviedb.org/t/p/w1920_and_h800_multi_faces/dclaBOdOgl2SiNxu59sMHkz1BZw.jpg')"}}>
                    <div className="p-4" style={{zIndex: 1}}>
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-3">
                                <Card>
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/movie-banner/animal.jpeg")}
                                        className="img-fluid h-100"
                                        width="400" height="600"
                                        rel="preload"
                                        type="image/webp"
                                    />
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-9 my-3">
                                <h2 className="text-white font-weight-700">Unlikely Animal Friends - Watch together on bingeAsk</h2>
                                <h4 className="text-light-gray">Overview</h4>
                                <p className="text-white small">The natural world is full of amazing stories, but sometimes animals act in ways that seemingly go against all their instincts. This heart-warming series examines the cases of animals that have struck up unusual relationships.</p>
                                <Button className="btn-icon btn-3 shadow-none mt-3" color="danger" type="button">
									<span className="btn-inner--icon">
										<i className="fa fa-video-camera" />
									</span>
									<span className="btn-inner--text">Watch Together</span>
								</Button>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    {/* <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <Card className="bg-dark">
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WeMJo701mvQ?si=uDpGLuzJKvdB1ypd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </Card>
                        </div>
                    </div> */}
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default ShowDetails;
  