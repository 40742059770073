const ChatBubbleCard = (props) => {
	return (
		<>
			<main className="msger-chat">
                <div className="msg left-msg">
                    <div className="msg-img" style={{ backgroundImage: "" }}></div>
                    <div className="msg-bubble bg-light-dark">
                        <div className="msg-text">
                            Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
                        </div>
                        <div className="msg-info">
                            <div className="msg-info-name">Yash - 12:45 AM</div>
                        </div>
                    </div>
                </div>

                <div className="msg right-msg">
                    <div className="msg-img" style={{ backgroundImage: "" }}></div>

                    <div className="msg-bubble">
                        <div className="msg-text">
                            You can change your name in JS section!
                        </div>
                        <div className="msg-info float-right">
                            <div className="msg-info-name">12:45 AM</div>
                        </div>
                    </div>
                </div>
                <div className="msg right-msg">
                    <div className="msg-img" style={{ backgroundImage: "" }}></div>

                    <div className="msg-bubble">
                        <div className="msg-text">
                            You can change your name in JS section! 🙈🐵🐵
                        </div>
                        <div className="msg-info float-right">
                            <div className="msg-info-name">12:45 AM</div>
                        </div>
                    </div>
                </div>
                <div className="msg right-msg">
                    <div className="msg-img" style={{ backgroundImage: "" }}></div>

                    <div className="msg-bubble">
                        <div className="msg-text">
                            <span style={{ fontSize: 30 }}>🙈🐵🐵🙈😄😁😆😅😂🤣😀🤭😃😬😈😭😣</span>
                        </div>
                        <div className="msg-info float-right">
                            <div className="msg-info-name">12:45 AM</div>
                        </div>
                    </div>
                </div>
            </main>
		</>
	)
}

export default ChatBubbleCard
