import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
	CardImg,
    CardBody
} from "reactstrap";

import UseFooter from "components/Footers/Footer";
import UseHeader from "components/Headers/Header";
  
  const Blog = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Blogs</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container">
                    <div className="row py-4">
                        <div className="col-sm-12 col-md-3 mb-3">
                            <Link to={'../blog-reading'}>
                                <Card className="bg-dark">
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/ott-icons/netflix-bg.webp")}
                                        className="img-fluid h-100"
                                        width="600" height="400"
                                        rel="preload"
										type="image/webp"
                                    />
                                    <CardBody className="bg-dark p-3">
                                        <h1 className="text-light-gray mb-0 font-weight-500" style={{fontSize: 14}}>How to watch netflix together?</h1>
                                        <p className="small text-light-gray fs-12 mt-3">Go to netflix.com and choose the video you want to watch. While on the video play screen, click the TP button to get the link to your party. Send the link to your friends. If they don't already have Teleparty installed, they will be guided to install it.</p>
                                    </CardBody>
                                </Card>
                            </Link>
                        </div>
                        <div className="col-sm-12 col-md-3 mb-3">
                            <Card className="bg-dark">
                                <CardImg
                                    alt="Watch Together - bingeAsk"
                                    src={require("../assets/img/ott-icons/prime-video-bg.webp")}
                                    className="img-fluid"
                                    rel="preload"
									type="image/webp"
                                />
                                <CardBody className="bg-dark">
                                    <h1 className="text-light-gray mb-0 font-weight-500" style={{fontSize: 14}}>How to watch netflix together?</h1>
                                    <p className="small text-light-gray fs-12 mt-3">Go to netflix.com and choose the video you want to watch. While on the video play screen, click the TP button to get the link to your party. Send the link to your friends. If they don't already have Teleparty installed, they will be guided to install it.</p>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default Blog;
  