import { NavLink as Link } from "react-router-dom"

const Header = () => {
	return (
		<>
			<Link to={'../'}>
				<img src={require("assets/img/logo/bingeAsk-light-full.webp")} className="img-fluid h-3" alt="bingeAsk Logo" width="160" height="300" />
			</Link>
			<div>
				<h1 className="fw-600 mt-2" style={{fontSize: 14}}><span className="text-light-gray">Find friends,</span> <span className="text-light-gray">Watch together</span></h1>
			</div>
		</>
	)
}

export default Header
