import { Helmet } from 'react-helmet';

import {
	FormGroup
} from "reactstrap"

import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header"
import UseBottomNav from "components/Navigation/BottomNav"

const Support = () => {

	return (
		<>
			<Helmet>
				<title>Support | bingeAsk</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
					<UseHeader />
				</div>
				<div className="container">
					<p className="font-weight-500 text-center text-light-gray my-4" style={{fontSize: 13}}>Contact us for any query or support</p>
					<div className="row justify-content-center">
                        <div className="col-md-4">
                            <FormGroup className="rounded-0 mb-3">
                                <textarea className="form-control bg-dark border-0" placeholder="Enter name and message..." />
                            </FormGroup>
                            <div className="text-center mb-5">
                                <button type="button" className="btn btn-primary mt-2">Send</button>
                            </div>
                        </div>
					</div>
				</div>
				<div className="section bg-dark py-7">
					<div className="container">
						<div className="row">
							<div className="col-md-12 col-sm-12 text-center">
								<h2 className="font-weight-700 text-white">How to watch Netflix together on bingeAsk?</h2>
							</div>
						</div>
						<div className="row mt-1">
							<div className="col-md-4 col-12 text-center">
								<div className="card shadow-none bg-a mt-4">
									<div className="card-body">
										<h3 className="font-weight-700 text-dark">Host Watch Party</h3>
										<h4 className="font-weight-500 small text-dark">Choose Netflix from the list of platforms on bingeAsk to host a Netflix party. Copy the party link and share it with your friends.</h4>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12 text-center">
								<div className="card shadow-none bg-s mt-4">
									<div className="card-body">
										<h3 className="font-weight-700 text-dark">Watch Movies and Shows</h3>
										<h4 className="font-weight-500 small text-dark">Choose the movie or show you want to watch and bingeAsk will automatically play, start syncing for your party in real time.</h4>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12 text-center">
								<div className="card shadow-none bg-k mt-4">
									<div className="card-body">
										<h3 className="font-weight-700 text-dark">Chat It Out</h3>
										<h4 className="font-weight-500 small text-dark">Spill the beans with text or face-to-face video chat. Watch movie together and share the vibe side by side, even if miles apart.</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<UseBottomNav page='home' />

				<div style={{ marginBottom: '14vh' }}>
					<UseFooter />
				</div>
			</div>
		</>
	)
}

export default Support
