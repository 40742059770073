import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Button
} from "reactstrap"

import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header";
  
  const PageNotFound = () => {
	
    return (
        <>
            <Helmet>
				<title>bingeAsk | Page Not Found</title>
			</Helmet>
			<div className="mt-4 page-header purple-filter">
				<div className="text-center">
                    <UseHeader />
				</div>
				
                <div className="container mt-0 mb-6 text-center">
                    <p style={{fontSize: '14rem'}} className="font-weight-800 mb-0">
						<span className="text-a" style={{opacity: '.2'}}>4</span>
						<span className="fuse-text">0</span>
						<span className="text-k" style={{opacity: '.2'}}>4</span>
					</p>
					<h2 className="text-light-gray  mt--5">Hello binge watcher, looks like you're lost on bingeAsk.</h2>
					<Link to={'../'}>
						<Button className="btn-icon btn-3 shadow-none mt-4" color="danger" type="button">
							Take me home
						</Button>
					</Link>
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default PageNotFound;
  