import React, { useEffect, useState } from 'react';
import {
    Input
} from "reactstrap"

import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { s3 } from "variables/awsHelper"
import { PutObjectCommand } from "@aws-sdk/client-s3"
import moment from 'moment'
import axios from 'axios'
import showAlert from '../Alert'

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginFileValidateType);

const CompleteSignUpCard = (prop) => {

    const [files, setFiles] = useState([])
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [pfp, setPfp] = useState('')
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')

    const signup = () => {
        if (firstName === '') {
            showAlert('danger', <b>Please enter your first name</b>)
            setTimeout(() => {
                document.getElementById('firstNameInput').focus()
            }, 100)
            return
        }
        if (lastName === '') {
            showAlert('danger', <b>Please enter your last name</b>)
            setTimeout(() => {
                document.getElementById('lastNameInput').focus()
            }, 100)
            return
        }
        if (email === '') {
            showAlert('danger', <b>Please enter your email address</b>)
            setTimeout(() => {
                document.getElementById('emailInput').focus()
            }, 100)
            return
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(email)) {
            showAlert('danger', <b>Please enter a valid email address</b>)
            return
        }

        if (pfp === '') {
            showAlert('danger', <b>Please upload a profile picture</b>)
            return
        }

        if (password === '' || password.length < 6) {
            showAlert('danger', <b>Please enter a password of minimum 6 characters</b>)
            return
        }

        setLoading(true)

        axios.post(process.env.REACT_APP_BACKEND_URL + 'user/me', {
            user_name: firstName + ' ' + lastName,
            user_email: email,
            user_password: password
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        }).then(() => {
            // Set localStorage
            localStorage.setItem('userEmail', email)
            localStorage.setItem('userName', firstName + ' ' + lastName)
            prop.completionEvent()
        }).catch((err) => {
            showAlert('danger', err)
            setLoading(false)
        })
    }

    useEffect(() => {
        document.getElementById('firstNameInput').focus()

        if (localStorage.getItem('pfp')) {
            setFiles([{ source: process.env.REACT_APP_PFP_URL + localStorage.getItem('pfp'), options: { type: 'local' } }])
            setPfp(localStorage.getItem('pfp'))
        }
    }, [])

    return (
        <>
            <div className="row justify-content-center mt-4 mx-0">
                <div className="col-lg-4 col-md-7">
                    <div className="card bg-dark border-0 mb-0">
                        <div className="card-body px-lg-5">
                            <form>
                                <div className="row justify-content-center mt-3">
                                    <div className="col-4 cursor-pointer">
                                        <FilePond
                                            files={files}
                                            onupdatefiles={setFiles}
                                            allowMultiple={false}
                                            maxFiles={1}
                                            allowImageResize={true}
                                            imageResizeTargetWidth={512}
                                            imageResizeTargetHeight={512}
                                            imageResizeUpscale={false}
                                            acceptedFileTypes={['image/png', 'image/gif', 'image/webp', 'image/jpg', 'image/jpeg']}
                                            server={{
                                                process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                                    if (file.name === localStorage.getItem('pfp')) {
                                                        load()
                                                        return
                                                    }
                                                    const timestamp = moment().format('X')
                                                    const invalidCharactersRegex = /[\\{}^%`[\]#"|~<>_ ]/g
                                                    const sanitizedFileName = file.name.replace(invalidCharactersRegex, '-')

                                                    const pfpName = localStorage.getItem('userId') + '-' + timestamp + '-' + sanitizedFileName

                                                    const params = {
                                                        Body: file,
                                                        Bucket: process.env.REACT_APP_S3_BUCKET,
                                                        Key: `js-uploads/${pfpName}`,
                                                        ContentType: file.type
                                                    }

                                                    try {
                                                        await s3.send(new PutObjectCommand(params))
                                                        axios.post(process.env.REACT_APP_BACKEND_URL + 'user/me', {
                                                            user_pfp: pfpName
                                                        }, {
                                                            headers: {
                                                                Authorization: `Bearer ${localStorage.getItem('jwt')}`
                                                            }
                                                        }).then(() => {
                                                            localStorage.setItem('pfp', pfpName)
                                                            setPfp(pfpName)
                                                            load()
                                                        }).catch((err) => {
                                                            error(err)
                                                        })
                                                    } catch(err) {
                                                        console.log(err)
                                                        error(err)
                                                    }
                                                },
                                                load: (source, load, error, progress, abort, headers) => {
                                                    var myRequest = new Request(source)
                                                    fetch(myRequest).then(function (response) {
                                                        response.blob().then(function (myBlob) {
                                                            load(myBlob)
                                                        })
                                                    })
                                                },
                                            }}
                                            name="files"
                                            credits={''}
                                            stylePanelLayout="compact circle"
                                            styleProgressIndicatorPosition="bottom center"
                                            styleButtonRemoveItemPosition="bottom center"
                                            allowProcess={false}
                                            stylePanelAspectRatio="1:1"
                                            labelIdle='Add Profile'
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text bg-light-dark border-0"><i className="ni ni-single-02"></i></span>
                                                </div>
                                                <Input className="form-control bg-light-dark border-0" placeholder="First name" id="firstNameInput" onInput={(e) => setFirstName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text bg-light-dark border-0"><i className="ni ni-single-02"></i></span>
                                                </div>
                                                <Input className="form-control bg-light-dark border-0" placeholder="Last name" id="lastNameInput" onInput={(e) => setLastName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="input-group input-group-merge input-group-alternative">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-light-dark border-0"><i className="ni ni-email-83"></i></span>
                                        </div>
                                        <input className="form-control bg-light-dark border-0" placeholder="Enter email" type="email" onInput={(e) => setEmail(e.target.value)} id="emailInput" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group input-group-merge input-group-alternative">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text bg-light-dark border-0"><i className="ni ni-lock-circle-open"></i></span>
                                        </div>
                                        <input className="form-control bg-light-dark border-0" placeholder="Password" type="text" value={password} onInput={(e) => { setPassword(e.target.value);e.target.setAttribute('type', 'password') }}/>
                                    </div>
                                </div>
                                <div className="text-center">
                                    {loading ? (
                                        <button type="button" className="btn btn-primary my-4" disabled={true}>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Almost there...
                                        </button>
                                    ) : (
                                        <button type="button" className="btn btn-primary my-4" onClick={signup}>All set!</button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompleteSignUpCard
