import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import "assets/plugins/nucleo/css/nucleo.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/scss/argon-dashboard-react.scss"
import "assets/css/custom.css"
import "assets/css/swiper-bundle.min.css"
import "assets/css/open-sans-font.css"

import MainLayout from "layouts/Main"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  	<BrowserRouter>
		<Routes>
			<Route path="*" element={<MainLayout />} />
		</Routes>
  	</BrowserRouter>
);

if (window.location.host.startsWith("www")){
    window.location = "http://" + window.location.host.replace('www.', '') + window.location.pathname
}