import { NavLink as Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import {
	Card,
	CardImg,
    Button
} from "reactstrap";

import UseFooter from "components/Footers/Footer";
import UseHeader from "components/Headers/Header";
  
  const Netflix = () => {

    const loadExtensionWindow = () => {
		window.open('https://chrome.google.com/webstore/detail/bingeask-watch-together/ijhfpmokhfibogihpnlafeadgobmhfhc', 'chromeStore', 'height=750,width=1024,left=350,top=100')
		
		window.onfocus = function() {
			window.location.reload()
		}
	}
	
    return (
        <>
            <Helmet>
				<title>Netflix Watch Party | bingeAsk</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
                    <UseHeader />
					<Link to={'../'} className="text-s"><i className="fa fa-arrow-left"></i></Link>
				</div>
				
                <div className="container rounded">
                    <div className="py-4" style={{zIndex: 1}}>
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-6">
                                <Card>
                                    <CardImg
                                        alt="Watch Together - bingeAsk"
                                        src={require("../assets/img/brand/netflix-together.webp")}
                                        className="img-fluid h-100"
                                        width="500" height="300"
                                        rel="preload"
                                        type="image/webp"
                                    />
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6 my-3">
                                <h3 className="text-s font-weight-700">Watch NETFLIX together using bingeAsk</h3>
                                <h2 className="text-white font-weight-700 h1">Host a Netflix watch party with friends</h2>
                                <h4 className="text-light-gray">Overview</h4>
                                <p className="text-white small">No more alone movie nights! You can find friend, love, partner to watch movie together. bingeAsk is the easiest, simplest and newest way of making friends.</p>
                            </div>
                            <div className="section bg-dark p-5 mt-7 rounded text-center">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 text-center">
                                        <h2 className="font-weight-700 text-white">How to use bingeAsk</h2>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-md-4 col-12 text-center">
                                        <div className="card shadow-none bg-a mt-4">
                                            <div className="card-body">
                                                <h2 className="h1 text-dark"><i className="fa fa-puzzle-piece"></i></h2>
                                                <h3 className="font-weight-700 text-dark">Install Extension</h3>
                                                <h4 className="font-weight-500 small text-dark">Choose Netflix from the list of platforms on bingeAsk to host a Netflix party. Copy the party link and share it with your friends.</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 text-center">
                                        <div className="card shadow-none bg-s mt-4">
                                            <div className="card-body">
                                                <h2 className="h1 text-dark"><i className="fa fa-film"></i></h2>
                                                <h3 className="font-weight-700 text-dark">Select Show on Netflix</h3>
                                                <h4 className="font-weight-500 small text-dark">Choose the movie or show you want to watch and bingeAsk will automatically play, start syncing for your party in real time.</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 text-center">
                                        <div className="card shadow-none bg-k mt-4">
                                            <div className="card-body">
                                                <h2 className="h1 text-dark"><i className="fa fa-share"></i></h2>
                                                <h3 className="font-weight-700 text-dark">Copy link & share with friend</h3>
                                                <h4 className="font-weight-500 small text-dark">Spill the beans with text or face-to-face video chat. Watch movie togther and share the vibe side by side, even if miles apart.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button className="btn-icon btn-3 shadow-none mt-5" id="install-extension-btn" color="danger" type="button" onClick={() => loadExtensionWindow()}>
									<span className="btn-inner--icon">
										<i className="fa fa-puzzle-piece" />
									</span>
									<span className="btn-inner--text">Install Extension</span>
								</Button>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    {/* <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <Card className="bg-dark">
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WeMJo701mvQ?si=uDpGLuzJKvdB1ypd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </Card>
                        </div>
                    </div> */}
                </div>
				<UseFooter />
			</div>
        </>
    );
  };
  
  export default Netflix;
  