import {
    Button
} from "reactstrap";

const ChatFooterCard = (props) => {
	return (
		<>
			<div className="row align-items-center">
                <div className="col-auto">
                    <Button color="primary" size="sm" type="button">
                        <i className="fa fa-smile"></i>
                    </Button>
                </div>
                <div className="col px-0">
                    <input type="text" className="form-control bg-light-dark text-light-gray" placeholder="Type message..." style={{ height: 'calc(2em + 1.25rem + 0px)' }} />
                </div>
                <div className="col-auto">
                    <Button color="primary" size="sm" type="button">
                        <i className="fa fa-arrow-up"></i>
                    </Button>
                </div>
            </div>
		</>
	)
}

export default ChatFooterCard
