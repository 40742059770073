import { Helmet } from 'react-helmet'
import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header"
import UseBottomNav from "components/Navigation/BottomNav"
import UseFindFriendCard from "components/Cards/FindFriend.jsx"
import axios from 'axios'
import { useEffect, useState } from 'react'
import { NavLink as Link } from "react-router-dom"

const FindFriends = () => {
	const [users, setUsers] = useState([])
	// const [page, setPage] = useState(1)
	// const [totalPage, setTotalPage] = useState(1)
	const [guest, setGuest] = useState(false)

	useEffect(() => {
		getUsers(1)
	}, [])

	const getUsers = (page) => {
		const jwt = localStorage.getItem('jwt')
		if (!jwt) {
			axios.get(`${process.env.REACT_APP_BACKEND_URL}limitedUsersList`).then((resp) => {
				setUsers(resp.data.data.users)
				// setPage(1)
				// setTotalPage(0)
				setGuest(true)
			})
			return
		}
		axios.get(`${process.env.REACT_APP_BACKEND_URL}user?page=${page}`, {
			headers: {
				Authorization: `Bearer ${jwt}`
			}
		}).then((resp) => {
			setUsers(resp.data.data.users)
			// setPage(resp.data.data.pageInfo.currentPage)
			// setTotalPage(resp.data.data.pageInfo.totalPages)
		})
	}

	// const getNextPage = () => {
	// 	getUsers(page + 1)
	// 	setPage(page + 1)
	// }

	return (
		<>
			<Helmet>
				<title>Find Friends | bingeAsk</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
					<UseHeader />
				</div>
				<div className="container">
					<div className="row py-4">
						<div className="col-12 col-md-12">
							<div className="row">
								{users.length === 0 ? (
									Array.from({ length: 9 }, (_, index) =>
										<div className="col-12 col-md-4" style={{marginBottom: 33}} key={index}>
											<div className="skeleton-loader card bg-dark border-0">
												<div className="card-body p-3">
													<div style={{ height: 45, width: 45 }} className="skeleton-block"></div>
													<div className="row px-2 mt-4">
														<div className="col px-1" >
															<div style={{ height: 100, width: '100%' }} className="skeleton-block"></div>
														</div>
														<div className="col px-1" >
															<div style={{ height: 100, width: '100%' }} className="skeleton-block"></div>
														</div>
														<div className="col px-1" >
															<div style={{ height: 100, width: '100%' }} className="skeleton-block"></div>
														</div>
														<div className="col px-1" >
															<div style={{ height: 100, width: '100%' }} className="skeleton-block"></div>
														</div>
														<div className="col px-1" >
															<div style={{ height: 100, width: '100%' }} className="skeleton-block"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								) : null }
								{users.map((user, i) =>
									<div className="col-12 col-md-4" style={{marginBottom: 33}} key={i}>
										<Link to={guest ? '/profile' : `../user/${user.user_id}`}>
											<UseFindFriendCard key={user.user_id} name={user.user_name} pfp={user.user_pfp} favs={user.show_posters} />
										</Link>
									</div>
								)}
							</div>
							{guest ? (

								<div className="row justify-content-center">
									<div className="col-5">
										<div className="alert alert-primary">
											<span className="alert-icon"><i className="fa fa-users"></i></span>
											<span className="alert-text"><strong>To find more people</strong> please sign up.</span>
											<Link to={`/profile`} className="text-decoration-none">
												<button className="btn btn-sm btn-secondary text-dark float-right shadow-none">Sign up</button>
											</Link>
										</div>
									</div>
								</div>

							) : null}
						</div>
					</div>
				</div>
				<UseBottomNav page="friends" />
				<div style={{ marginBottom: '14vh' }}>
					<UseFooter />
				</div>
			</div>
		</>
	)
}

export default FindFriends
