import classnames from "classnames"
import { NavLink as Link } from "react-router-dom"

import {
	Nav,
	NavItem,
	NavLink
} from "reactstrap"

const BottomNav = (props) => {
	// const [navPills, setNavPills] = useState(1)
	const { page } = props

	return (
		<>
			<div className="justify-content-center bottom-nav-bg">
				<div className="justify-content-center bottom-nav">
					<Nav
						className="nav-fill flex-sm-row  l-15 r-15"
						id="tabs-text"
						pills
						role="tablist"
					>
						<NavItem className="mb-0">
							<Link to={'/home'} className="text-white">
								<NavLink
									aria-selected={page === 'home'}
									className={classnames("mb-sm-3 mb-md-0", {
										active: page==='home'
									})}
									role="Home Tab"
								>
									Home
								</NavLink>
							</Link>
						</NavItem>
						<NavItem className="mb-0">
							<Link to={'/find-friends'} className="text-white">
								<NavLink
									aria-selected={page === 'friends'}
									className={classnames("mb-sm-3 mb-md-0", {
										active: page === 'friends'
									})}
									role="Find Friends Tab"
								>
									Find Friends
								</NavLink>
							</Link>
						</NavItem>
						<NavItem className="mb-0">
							<Link to={'/messages'} className="text-white">
								<NavLink
									aria-selected={page === 'messages'}
									className={classnames("mb-sm-3 mb-md-0", {
										active: page === 'messages'
									})}
									// onClick={() => setNavPills(3)}
									role="Messages Tab"
								>
									Messages
								</NavLink>
							</Link>
						</NavItem>
						<NavItem className="mb-0">
							<Link to={'/profile'} className="text-white">
								<NavLink
									aria-selected={page === 'profile'}
									className={classnames("mb-sm-3 mb-md-0", {
										active: page === 'profile'
									})}
									// onClick={() => setNavPills(4)}
									role="Profile Tab"
								>
									Profile
								</NavLink>
							</Link>
						</NavItem>
					</Nav>
				</div>
			</div>
		</>
	)
}

export default BottomNav
