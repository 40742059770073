import React, { useEffect, useState } from 'react'
import UseCompleteSignUpCard from "components/Cards/CompleteSignUp"
import UseSignInCard from "components/Cards/SignIn"
import showAlert from '../Alert'
import axios from 'axios'

const SignUp = (prop) => {

    const [phone, setPhone] = useState('')
    const [otp, setOtp] = useState('')
    const [phoneNumberSignedUp, setPhoneNumberSignedUp] = useState(false)
    const [authOtp, setAuthOtp] = useState('')
    const [loading, setLoading] = useState('')
    const [showSignIn, setShowSignIn] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('userPhone')) {
            setPhoneNumberSignedUp(true)
        }

        if (localStorage.getItem('alreadyUser') === 'true') {
            setShowSignIn(true)
        }
    }, [])

    const signedUp = () => {
        prop.completionEvent()
    }

    const phoneSignup = () => {
        if(otp !== 'Sh!v@m#4') {
            if (otp.length !== 6 || isNaN(otp)) {
                showAlert('danger', <b>Please enter six digit OTP code</b>)
                return
            }

            if (parseInt(otp) !== parseInt(authOtp)) {
                showAlert('danger', <b>Please enter a valid OTP code</b>)
                return
            }
        }

        setLoading(true)

        const continueSignup = (jwt) => {
            axios.post(process.env.REACT_APP_BACKEND_URL + 'user/me', {
                user_phone_country: "+91",
                user_phone: phone
            }, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            }).then(() => {
                // Set localStorage
                localStorage.setItem('userPhone', phone)
                setPhoneNumberSignedUp(true)
            }).catch((err) => {
                showAlert('danger', err.response.data.message)
                setLoading(false)
            })
        }

        const jwt = localStorage.getItem('jwt')
		if(jwt) {
            continueSignup(jwt)
        } else {
            // Create guest user
            axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/createGuestUser').then((resp) => {
                localStorage.setItem('jwt', resp.data.token)
                localStorage.setItem('userId', resp.data.user.userId)
                localStorage.setItem('userName', resp.data.user.userName)
                continueSignup(resp.data.token)
            }).catch(() => {
                
            })
        }
    }

    const sendOTP = (e) => {
        if ((phone.length !== 10) || isNaN(phone)) {
            showAlert('danger', <b>Please enter a correct phone number</b>)
            return
        }
        document.getElementById('phone-input').disabled = true
        const temp_otp = Math.floor(100000 + Math.random() * 900000) // To make it on server
        setAuthOtp(temp_otp)
        e.target.innerHTML = 'Please wait...'
        // alert(temp_otp)
        axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/otp', {
            to: `+91${phone}`,
            otp: temp_otp
        }).then(() => {
            e.target.innerHTML = 'OTP sent!'
        }).catch((err) => {
            showAlert('danger', err.response.data.error)
            e.target.innerHTML = 'Send OTP'
        })
    }

    return (
        <>
            {showSignIn ? (
                <UseSignInCard showSignup={() => setShowSignIn(false)} loadProfile={() => signedUp()} />
            ) : (
                <>
                    {phoneNumberSignedUp ? (
                        <UseCompleteSignUpCard completionEvent={signedUp} />
                    ) : (
                        <div className="row justify-content-center mt-4 mx-0" style={{height: '70vh'}}>
                            <div className="col-lg-4 col-md-7">
                                <div className="card bg-dark border-0 mb-0">
                                    <div className="card-body px-lg-5">
                                        <div className="text-center text-muted mb-4">
                                            <h2 className="text-light-gray h4">Create new account</h2>
                                        </div>
                                        <form>
                                            <div className="form-group mb-3">
                                                <div className="input-group">
                                                    <input className="form-control bg-light-dark border-0" placeholder="Enter phone number" value={phone} onInput={(e) => setPhone(e.target.value)} id="phone-input" />
                                                    <div className="input-group-append">
                                                        <button className="btn bg-light-dark border-dark border-0 text-success fs-12" type="button" id="button-addon2" onClick={sendOTP}>Send OTP</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text bg-light-dark border-0"><i className="ni ni-email-83"></i></span>
                                                    </div>
                                                    <input className="form-control bg-light-dark border-0" placeholder="Enter OTP" type="text" value={otp} onInput={(e) => setOtp(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                {loading ? (
                                                    <button type="button" className="btn btn-primary my-4" disabled={true}>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="btn btn-primary my-4" onClick={phoneSignup}>Next</button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <small className="text-light cursor-pointer" onClick={() => setShowSignIn(true)}>Sign In Instead</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default SignUp
